"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerStatus = void 0;
var uuid_1 = require("uuid");
var PlayerStatus;
(function (PlayerStatus) {
    PlayerStatus[PlayerStatus["PLAYING"] = 0] = "PLAYING";
    PlayerStatus[PlayerStatus["PAUSED"] = 1] = "PAUSED";
    PlayerStatus[PlayerStatus["LEFT"] = 2] = "LEFT";
})(PlayerStatus = exports.PlayerStatus || (exports.PlayerStatus = {}));
var Player = /** @class */ (function () {
    function Player(name) {
        this.status = PlayerStatus.PLAYING;
        this.handouts = 0;
        this.name = name;
        this.lastSeen = new Date();
        this.id = uuid_1.v4();
        this.drunk = 0;
        this.toDrink = 0;
        this.connections = 0;
    }
    Player.prototype.canDrink = function () {
        return this.status === PlayerStatus.PLAYING || this.status === PlayerStatus.PAUSED;
    };
    Player.prototype.canRoll = function () {
        return this.status === PlayerStatus.PLAYING;
    };
    Player.prototype.leave = function () {
        this.status = PlayerStatus.LEFT;
    };
    Player.prototype.resume = function () {
        this.status = PlayerStatus.PLAYING;
    };
    Player.prototype.pause = function () {
        this.status = PlayerStatus.PAUSED;
    };
    return Player;
}());
exports.default = Player;
