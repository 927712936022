import React from 'react';
import AppBase from "./components/AppBase";

function App() {
  return (
    <AppBase />
  );
}

export default App;
