"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var uuid_1 = require("uuid");
var RequestMessage = /** @class */ (function () {
    function RequestMessage(type) {
        this.id = uuid_1.v4();
        this.type = type;
    }
    return RequestMessage;
}());
exports.default = RequestMessage;
