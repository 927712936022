"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrinkRequest = exports.ErrorRequest = exports.NewGameRequest = exports.RequestMessage = exports.RejoinRequest = exports.RollRequest = exports.PauseRequest = exports.ResumeRequest = exports.RenameRequest = exports.LeaveRequest = exports.HandoutRequest = exports.JoinRequest = void 0;
var JoinRequest_1 = require("./JoinRequest");
Object.defineProperty(exports, "JoinRequest", { enumerable: true, get: function () { return JoinRequest_1.default; } });
var HandoutRequest_1 = require("./HandoutRequest");
Object.defineProperty(exports, "HandoutRequest", { enumerable: true, get: function () { return HandoutRequest_1.default; } });
var LeaveRequest_1 = require("./LeaveRequest");
Object.defineProperty(exports, "LeaveRequest", { enumerable: true, get: function () { return LeaveRequest_1.default; } });
var RenameRequest_1 = require("./RenameRequest");
Object.defineProperty(exports, "RenameRequest", { enumerable: true, get: function () { return RenameRequest_1.default; } });
var ResumeRequest_1 = require("./ResumeRequest");
Object.defineProperty(exports, "ResumeRequest", { enumerable: true, get: function () { return ResumeRequest_1.default; } });
var PauseRequest_1 = require("./PauseRequest");
Object.defineProperty(exports, "PauseRequest", { enumerable: true, get: function () { return PauseRequest_1.default; } });
var RollRequest_1 = require("./RollRequest");
Object.defineProperty(exports, "RollRequest", { enumerable: true, get: function () { return RollRequest_1.default; } });
var RejoinRequest_1 = require("./RejoinRequest");
Object.defineProperty(exports, "RejoinRequest", { enumerable: true, get: function () { return RejoinRequest_1.default; } });
var RequestMessage_1 = require("./RequestMessage");
Object.defineProperty(exports, "RequestMessage", { enumerable: true, get: function () { return RequestMessage_1.default; } });
var NewGameRequest_1 = require("./NewGameRequest");
Object.defineProperty(exports, "NewGameRequest", { enumerable: true, get: function () { return NewGameRequest_1.default; } });
var ErrorRequest_1 = require("./ErrorRequest");
Object.defineProperty(exports, "ErrorRequest", { enumerable: true, get: function () { return ErrorRequest_1.default; } });
var DrinkRequest_1 = require("./DrinkRequest");
Object.defineProperty(exports, "DrinkRequest", { enumerable: true, get: function () { return DrinkRequest_1.default; } });
